import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import {
  RelatedList,
  RelatedListItem,
  RelatedSettings as RelatedSettingsContainer,
} from 'components/RelatedSettings'
import { DomainPrebidStack } from 'features/inventory/domain/types/domainPrebidStack'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'

type Props = Pick<DomainPrebidStack, 'auctionDelay'>

export const RelatedSettings = ({ auctionDelay }: Props): JSX.Element => {
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.advancedPrebidSettings',
  })

  const { workspaceId } = useWorkspaceParam()

  return (
    <RelatedSettingsContainer title={t('relatedSettings.title')}>
      <RelatedList>
        <RelatedListItem
          content={t('relatedSettings.auctionDelay', {
            value: auctionDelay,
          })}
          url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, { workspaceId })}
        />
      </RelatedList>
    </RelatedSettingsContainer>
  )
}
