import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModule } from 'features/globalSetup/types/prebidModule'
import { useAbility } from 'providers/casl'
import { EditSchema } from '../../schemas/editSchema'

export const ModuleEnabledSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.general.edit.general.enabled',
  })
  const { control } = useFormContext<EditSchema>()
  const ability = useAbility()

  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  if (!workspacePrebidModule) {
    return <Loading />
  }
  const name: PrebidModule['name'] = workspacePrebidModule.prebidModule.name

  return (
    <Paper>
      <SectionHeader
        subtitle={t('subtitle', { name })}
        title={t('title', { name })}
        titleEndAdornment={
          <Switch
            control={control}
            disabled={ability.cannot('update', 'GlobalSetupFeature-general')}
            name='enabled'
          />
        }
      />
    </Paper>
  )
}
