import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../constants'
import { Schema } from '../schema'

export const AdvancedPrebidSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.advancedPrebidSettings',
  })
  const { t: tc } = useTranslation('common')
  const { control } = useFormContext<Schema>()

  const ability = useAbility()
  const cannotUpdate: boolean = ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        title={t('title')}
      />

      <BoxForm>
        <NumberField
          control={control}
          disabled={cannotUpdate}
          label={t('auctionDelay.label')}
          name='auctionDelay'
          tooltip={{
            content: t('auctionDelay.tooltip'),
            link: {
              title: tc('learnMore'),
              url: KNOWLEDGE_BASE_URL.TIMEOUTS,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}
