import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { Form } from './Form'
import { Schema, schema, schemaDefault } from './schema'
import { useUpdateDomainRefresher } from './useUpdateDomainRefresher'

export const FormContainer = (): JSX.Element => {
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { errors: apiErrors, update } = useUpdateDomainRefresher()

  if (!domain) {
    return <Loading />
  }

  return (
    <FormProvider<Schema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(domain)}
    >
      {() => <Form />}
    </FormProvider>
  )
}
