import { useContext } from 'react'

import { Form } from 'components/Form'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { CommonForm } from './CommonForm'
import { useCreateAdUnit } from './hooks/useCreateAdUnit'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/addSchema'

export const GeneralFormAdd = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(AddEditAdUnitContext)
  const { createAdUnit, errors: apiErrors } = useCreateAdUnit()

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={data => createAdUnit(data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => <CommonForm variant='add' />}
    </Form>
  )
}
