import { useContext } from 'react'

import { Form as FormContainer } from 'components/Form'
import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { Form } from './Form'
import { useConnectPrebidStackToGam } from './hooks/useConnectPrebidStackToGam'
import { Schema, schema, schemaDefault } from './schema'

export const SetupPrebidStackForm = (): JSX.Element => {
  const { connect, errors: apiErrors } = useConnectPrebidStackToGam()
  const { formId, gamSetupOrder, loading, setTouched, setValid } = useContext(
    SetupPrebidStackSectionContext,
  )
  const { workspaceId } = useWorkspaceParam()

  return (
    <>
      <FormContainer<Schema>
        externalErrors={apiErrors}
        id={formId}
        loading={loading}
        onSubmit={connect}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={schemaDefault(workspaceId, gamSetupOrder)}
      >
        {() => <Form />}
      </FormContainer>
    </>
  )
}
