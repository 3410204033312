import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { useAbility } from 'providers/casl'
import { ParamsFormBox } from '../../components/ParamsFormBox'

type Props = {
  parametersSchema: PrebidModuleParameter[]
}

export const ParametersSection = ({ parametersSchema }: Props): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.general.edit.general.parameters',
  })

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        title={t('title')}
      />

      <ParamsFormBox
        isDisabled={ability.cannot('update', 'GlobalSetupFeature-general')}
        parametersSchema={parametersSchema}
      />
    </Paper>
  )
}
