import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CommonFormContextProps, SaveButton, useCommonFormContextProps } from 'components/Form'
import { PageHeader } from 'components/Header'
import Loading from 'components/Loading'
import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import {
  ProtectionContext,
  ProtectionContextType,
} from 'features/globalSetup/contexts/ProtectionContext'
import { EditFilteringRuleForm } from 'features/globalSetup/forms/Prebid/Protection/EditFilteringRuleForm'
import { useGetFilteringRule } from 'features/globalSetup/pages/Prebid/Protection/pages/hooks/useGetFilteringRule'
import { useAbility } from 'providers/casl'
import { UrlParams } from './types'

export const ProtectionEditPage = (): JSX.Element => {
  const { id: urlFilteringRuleId } = useParams<UrlParams>()
  const ability = useAbility()
  const { filteringRule } = useGetFilteringRule({
    id: urlFilteringRuleId,
  })
  const { t } = useTranslation(['features/globalSetup', 'common'])

  const commonContextProps: CommonFormContextProps = useCommonFormContextProps({
    formId: 'prebid-protection-form-edit',
  })

  if (filteringRule === undefined) {
    return <Loading />
  }

  const { name } = filteringRule

  const contextProps: ProtectionContextType = {
    filteringRule,
    ...commonContextProps,
  }

  return (
    <ProtectionContext.Provider value={contextProps}>
      <ProtectionContext.Consumer>
        {(ctxProps: ProtectionContextType): JSX.Element => (
          <PageHeader
            actions={
              <SaveButton
                {...ctxProps}
                can={ability.can('update', 'GlobalSetupFeature-protection')}
              />
            }
            description={{
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE.URL_FILTERING,
              },
              text: t('prebid.protection.form.header.description'),
            }}
            title={name}
          />
        )}
      </ProtectionContext.Consumer>

      <EditFilteringRuleForm />
    </ProtectionContext.Provider>
  )
}
