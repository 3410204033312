import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { BoxForm, NumberField, Slider } from 'components/Form'
import { SliderMark } from 'components/Form/Slider/types'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { RelatedList, RelatedListItem, RelatedSettings } from 'components/RelatedSettings'
import { useAuthUser } from 'features/user'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useS2sTimeoutMultiplierMarks } from './hooks/useS2sTimeoutMultiplierMarks'
import { Schema } from './schema'
import { AdvancedPrebidSection } from './sections/AdvancedPrebidSection'
import { ConsentManagementSection } from './sections/ConsentManagementSection'
import { NonConsentTrafficMonetizationSection } from './sections/NonConsentTrafficMonetizationSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const {
    user: { currentWorkspace },
  } = useAuthUser()
  const { control } = useFormContext<Schema>()

  const s2sTimeoutMultiplierMarks: SliderMark[] = useS2sTimeoutMultiplierMarks()

  const ability = useAbility()
  const isDisabled = ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')

  return (
    <>
      <Paper>
        <BoxForm>
          <SectionHeader
            subtitle={t('prebid.defaults.timeouts.subtitle')}
            title={t('prebid.defaults.timeouts.title')}
          />

          <Stack
            alignItems='flex-start'
            direction='row'
            spacing={2}
          >
            <NumberField
              control={control}
              disabled={isDisabled}
              label={t('prebid.defaults.timeouts.prebidTimeout.label')}
              name='prebidTimeout'
              tooltip={{
                content: t('prebid.defaults.timeouts.prebidTimeout.tooltip'),
                link: {
                  title: t('common:learnMore'),
                  url: KNOWLEDGE_BASE_URL.TIMEOUTS,
                },
              }}
            />

            {currentWorkspace && (
              <RelatedSettings>
                <RelatedList>
                  <RelatedListItem
                    content={t('relatedSettings.failsafeTimeout')}
                    url={generatePath(ROUTES.GLOBAL_SETUP.GENERAL.ROOT, {
                      tab: 'workspace',
                      workspaceId: currentWorkspace.id,
                    })}
                    value={currentWorkspace.failsafeTimeout}
                  />
                </RelatedList>
              </RelatedSettings>
            )}
          </Stack>

          <Slider
            control={control}
            disabled={isDisabled}
            label={t('prebid.defaults.timeouts.s2sTimeoutMultiplier.label')}
            marks={s2sTimeoutMultiplierMarks}
            name='s2sTimeoutMultiplier'
            tooltip={{
              content: t('prebid.defaults.timeouts.s2sTimeoutMultiplier.tooltip'),
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE_URL.TIMEOUTS,
              },
            }}
            withInput
          />
        </BoxForm>
      </Paper>

      <ConsentManagementSection />
      <NonConsentTrafficMonetizationSection />
      <AdvancedPrebidSection />
    </>
  )
}
