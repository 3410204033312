import { useContext } from 'react'

import { Form as FormContainer } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { Form } from './Form'
import { useUpdateDomainUnfilledRecovery } from './hooks/useUpdateDomainUnfilledRecovery'
import { Schema, schema, schemaDefault } from './schema'

export const UnfilledRecoveryForm = (): JSX.Element => {
  const { domain, formId, loading, setTouched, setValid } = useContext(AddEditDomainContext)
  const { errors: apiErrors, updateDomainUnfilledRecovery } = useUpdateDomainUnfilledRecovery()

  if (!domain) {
    return <Loading />
  }

  return (
    <FormContainer<Schema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={updateDomainUnfilledRecovery}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(domain)}
    >
      {() => <Form />}
    </FormContainer>
  )
}
