import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { ModulesCommonTabs } from 'features/globalSetup'
import { GeneralPage } from '../../pages/GeneralPage'
import { InventoryConnectionPage } from '../../pages/InventoryConnectionPage'

export const useTabs = (): Tab<ModulesCommonTabs>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.vendorSpecific.edit.tabs',
  })

  return [
    {
      element: 'general',
      label: t('general'),
      page: <GeneralPage />,
    },
    {
      element: 'inventory-connection',
      label: t('inventoryConnection'),
      page: <InventoryConnectionPage />,
    },
  ]
}
