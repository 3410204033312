import { useContext } from 'react'

import { Form } from 'components/Form'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { commonSchema } from 'features/globalSetup/forms/Prebid/Bidders/schemas/commonSchema'
import { EditBidderFormBody } from './EditBidderFormBody'
import { useUpdateWorkspaceBidder } from './hooks/useUpdateWorkspaceBidder'
import { EditSchema, editSchemaDefault } from './schemas/editSchema'

export const EditBidderForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspaceBidder } =
    useContext(WorkspaceBidderContext)

  if (!workspaceBidder) {
    throw Error('Workspace Bidder should be accessible')
  }
  const { bidderCode, id } = workspaceBidder

  const { errors: apiErrors, update } = useUpdateWorkspaceBidder({
    bidderCode,
    id,
  })

  return (
    <Form<EditSchema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={editSchemaDefault(workspaceBidder)}
    >
      {() => <EditBidderFormBody />}
    </Form>
  )
}
