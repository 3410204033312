import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { BoxForm, CodeField, NumberField } from 'components/Form'
import TextField, { TextFieldProps } from 'components/Form/TextField'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { WorkspacePrebidModuleParameter } from 'features/globalSetup/types/workspacePrebidModule'
import { ParamsSchema } from '../../schemas/paramsSchema'
import { CODE_FIELD_HEIGHT } from './constants'
import { ParamsFormBoxProps } from './types'
import { prepareValue } from './utils'

export const ParamsFormBox = ({
  isDisabled,
  parametersSchema,
}: ParamsFormBoxProps): JSX.Element => {
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const { control, register } = useFormContext<ParamsSchema>()

  const parameters: WorkspacePrebidModuleParameter[] = workspacePrebidModule?.parameters || []

  return (
    <BoxForm>
      {parametersSchema.map((prebidModuleParameter: PrebidModuleParameter): JSX.Element => {
        const { label, name, tooltip, type } = prebidModuleParameter

        register(name, { value: prepareValue(name, parameters) })

        const props: TextFieldProps<ParamsSchema> = {
          control,
          disabled: isDisabled,
          key: name,
          label,
          name: name,
          tooltip,
        }

        if (type === 'string') {
          if (name === 'topics') {
            return (
              <CodeField
                height={CODE_FIELD_HEIGHT}
                key={name}
                language='json'
                options={{
                  folding: false,
                  lineNumbers: 'off',
                }}
                title={name}
                {...props}
              />
            )
          } else {
            return (
              <TextField
                key={name}
                {...props}
              />
            )
          }
        } else {
          return (
            <NumberField
              key={name}
              {...props}
              type={type}
            />
          )
        }
      })}
    </BoxForm>
  )
}
