import { CSSObject, Theme } from '@mui/material'
import { kebabCase } from 'change-case'
import { KebabCase } from 'type-fest'

import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { Ability } from 'providers/casl'
import { PrebidModuleType } from './types/prebidModule'

type CanAccessProps = {
  ability: Ability
  moduleType: PrebidModuleType
}

export type YieldbirdStatusColor = {
  status: YieldbirdStatus
  theme: Theme
}

export const canAccess = ({ ability, moduleType }: CanAccessProps): boolean =>
  ability.can(
    'access',
    `GlobalSetupPage-prebid-modules-${kebabCase(moduleType) as KebabCase<PrebidModuleType>}-edit`,
  )

export const yieldbirdStatusColor = ({
  status,
  theme,
}: YieldbirdStatusColor): CSSObject['color'] => {
  const {
    palette: {
      error: { main: error },
      success: { main: success },
      warning: { main: warning },
    },
  } = theme

  switch (status) {
    case 'available': {
      return success
    }
    case 'denied': {
      return error
    }
    case 'requested': {
      return warning
    }
  }
}
