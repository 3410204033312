import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { PrebidStackFormContext } from './context/PrebidStackFormContext'
import { Form } from './Form'
import { useGetAdUnitBidders } from './hooks/useGetAdUnitBidders'
import { useUpdateAdUnitPrebidStack } from './hooks/useUpdateAdUnitPrebidStack'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { adUnit, formId, loading, setTouched, setValid } = useContext(AddEditAdUnitContext)
  const { adUnitBidders } = useGetAdUnitBidders({ adUnitId: adUnit?.id })
  const { update } = useUpdateAdUnitPrebidStack()

  if (!(adUnit && adUnitBidders)) {
    return <Loading />
  }

  return (
    <PrebidStackFormContext.Provider value={{ adUnitBidders }}>
      <FormProvider<Schema>
        id={formId}
        loading={loading}
        onSubmit={update}
        onTouched={setTouched}
        onValid={setValid}
        schema={schema}
        schemaDefaults={schemaDefault({ adUnit, adUnitBidders })}
      >
        {() => <Form />}
      </FormProvider>
    </PrebidStackFormContext.Provider>
  )
}
