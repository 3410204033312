import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainPrebidStack } from '../../types/domainPrebidStack'
import { DomainPrebidStackDTO } from '../types/domainPrebidStackDTO'

export const domainPrebidStackFromDTO = (from: DomainPrebidStackDTO): DomainPrebidStack =>
  mapDTO<DomainPrebidStackDTO, DomainPrebidStack>({ from }).transform(
    (domainPrebidStackDTO: DomainPrebidStackDTO): DomainPrebidStack => {
      const {
        createdAt,
        customizeNonConsentTrafficMonetization,
        domainAmazonEnabled,
        updatedAt,
        ...rest
      } = domainPrebidStackDTO

      return {
        ...rest,
        createdAt: new Date(createdAt),
        customNonConsentTrafficMonetizationEnabled: customizeNonConsentTrafficMonetization,
        domainAmazonEnabled: domainAmazonEnabled ?? undefined,
        updatedAt: new Date(updatedAt),
      }
    },
  )
