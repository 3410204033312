import { Form as FormContainer } from 'components/Form'
import { Form } from './Form'
import { useConfirmResetPassword } from './hooks/useConfirmResetPassword'
import { Schema, schema, schemaDefaults } from './schema'
import { UserConfirmResetPasswordProps } from './types'

export const UserConfirmResetPasswordForm = ({
  resetPasswordToken,
}: UserConfirmResetPasswordProps): JSX.Element => {
  const { confirmResetPassword, errors: apiErrors, loading } = useConfirmResetPassword()

  return (
    <FormContainer<Schema>
      externalErrors={apiErrors}
      loading={loading}
      onSubmit={confirmResetPassword}
      schema={schema}
      schemaDefaults={schemaDefaults(resetPasswordToken)}
    >
      {() => <Form />}
    </FormContainer>
  )
}
