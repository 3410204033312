import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { Schema } from '../../schema'

export const NonConsentTrafficMonetizationSection = (): JSX.Element => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.nonConsentTrafficMonetization',
  })

  const ability = useAbility()
  const { control } = useFormContext<Schema>()

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tC('learnMore'),
              url: KNOWLEDGE_BASE_URL.NON_CONSENT_TRAFFIC_MONETIZATION,
            }}
          />
        }
        title={t('title')}
      />

      <Switch
        control={control}
        disabled={ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')}
        label={t('monetizeNonConsentTraffic')}
        labelPlacement='start'
        name='monetizeNonConsentTraffic'
      />
    </Paper>
  )
}
