import { Editor as CodeEditor, OnMount } from '@monaco-editor/react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { editor } from 'monaco-editor'
import { useRef } from 'react'
import { Controller, ControllerFieldState, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Loading from 'components/Loading'
import Tooltip from 'components/Tooltip'
import { GLOBAL_BORDER_RADIUS } from 'providers/material-ui/theme/constants'
import { DEFAULT_HEIGHT } from './constants'
import { CodeFieldProps } from './types'

export const CodeField = <T extends FieldValues>({
  control,
  disabled,
  height = DEFAULT_HEIGHT,
  language,
  name,
  options,
  title,
  tooltip: tooltipProps,
}: CodeFieldProps<T>): JSX.Element => {
  const { t } = useTranslation('common')
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null)

  const handleOnMount: OnMount = editor => {
    editorRef.current = editor
  }

  const formatEditor = (editor: editor.IStandaloneCodeEditor): void => {
    editor.getAction('editor.action.formatDocument')?.run()
  }

  const setCustomStyles = (theme: Theme, error: ControllerFieldState['error']): SxProps<Theme> => ({
    border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[400]}`,
    borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
    pb: 1,
  })

  const Header = (): JSX.Element => (
    <FlexBox axis='x'>
      <Typography sx={{ m: 1 }}>{title}</Typography>
      {tooltipProps && <Tooltip {...tooltipProps} />}

      <Box sx={{ flexGrow: 1 }} />
      <Button
        onClick={(): void => {
          editorRef.current && formatEditor(editorRef.current)
        }}
        size='small'
        sx={{ m: 1 }}
        variant='outlined'
      >
        {t('form.action.format')}
      </Button>
    </FlexBox>
  )

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
        <FormControl error={Boolean(error)}>
          <Box
            id={name}
            sx={(theme: Theme) => ({ ...setCustomStyles(theme, error) })}
          >
            <Header />

            <Divider />

            <CodeEditor
              height={height}
              language={language}
              loading={<Loading />}
              onChange={onChange}
              onMount={handleOnMount}
              options={{
                ...options,
                minimap: { enabled: false },
                readOnly: disabled,
                renderLineHighlightOnlyWhenFocus: true,
                scrollbar: { alwaysConsumeMouseWheel: false },
                scrollBeyondLastLine: false,
              }}
              value={value}
            />
          </Box>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
