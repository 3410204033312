import { Checkbox, FormControlLabel } from '@mui/material'
import { ChangeEvent, useContext } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import { FilterableListBoxContext } from 'components/FilterableListBox'
import { CheckboxListOption } from '../../types'
import { CheckboxListProps } from './types'

export const CheckboxList = <T extends FieldValues>({
  disabled,
  field,
  name,
  options,
}: CheckboxListProps<T>): JSX.Element[] => {
  const { setValue } = useFormContext<T>()
  const { filter } = useContext(FilterableListBoxContext)

  const filteredOptions: CheckboxListOption[] = options.filter(
    ({ label }: CheckboxListOption): boolean => !filter || label.includes(filter),
  )

  return filteredOptions.map(({ label, value }: CheckboxListOption): JSX.Element => {
    const id = `${name}-${value}`

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value.includes(value)}
            id={id}
            key={id}
            onChange={(_event: ChangeEvent<HTMLInputElement>, checked: boolean): void =>
              setValue(
                name,
                checked
                  ? [...field.value, value]
                  : field.value.filter((tempValue: string): boolean => tempValue !== value),
                { shouldDirty: true, shouldValidate: true },
              )
            }
          />
        }
        disabled={disabled}
        key={id}
        label={label}
        sx={{ px: 2, width: '100%' }}
      />
    )
  })
}
