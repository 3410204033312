import { useContext } from 'react'

import { Form } from 'components/Form'
import { WorkspaceFormContext } from 'features/workspace/contexts/WorkspaceFormContext'
import { CommonForm } from './CommonForm'
import { useCreateWorkspace } from './hooks/useCreateWorkspace'
import { AddSchema, addSchema, addSchemaDefault } from './schemas/add'

export const WorkspaceAddForm = (): JSX.Element => {
  const { formId, loading, setTouched, setValid } = useContext(WorkspaceFormContext)
  const { create, errors: apiErrors } = useCreateWorkspace()

  return (
    <Form<AddSchema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={create}
      onTouched={setTouched}
      onValid={setValid}
      schema={addSchema}
      schemaDefaults={addSchemaDefault}
    >
      {() => <CommonForm />}
    </Form>
  )
}
