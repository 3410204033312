import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { usePrevious } from 'hooks/usePrevious'
import { useGetPrebidModuleParametersSchema } from '../hooks/useGetPrebidModuleParametersSchema'
import { EditSchema } from '../schemas/editSchema'
import { unregisterParameters } from '../utils'
import { ModuleEnabledSection } from './sections/ModuleEnabledSection'
import { ParametersSection } from './sections/ParametersSection'

export const GeneralForm = (): JSX.Element => {
  const { unregister, watch } = useFormContext<EditSchema>()
  const { prebidModuleId } = watch()
  const { parametersSchema } = useGetPrebidModuleParametersSchema({ id: prebidModuleId })
  const previousParametersSchema = usePrevious<PrebidModuleParameter[]>(parametersSchema)

  useEffect(() => {
    if (previousParametersSchema && previousParametersSchema.length > 0) {
      unregisterParameters<EditSchema>(previousParametersSchema, unregister)
    }
  }, [parametersSchema]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ModuleEnabledSection />
      {parametersSchema.length > 0 && <ParametersSection {...{ parametersSchema }} />}
    </>
  )
}
