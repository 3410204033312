import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { usePriceGeniusAlwaysOn } from '../../hooks/usePriceGeniusAlwaysOn'
import { useSwitchAdUnitProduct } from '../hooks/useSwitchAdUnitProduct'
import { CommonForm } from './CommonForm'
import { CommonSchema, commonSchema, schemaDefault } from './schemas/commonSchema'

export const PriceGeniusCommonForm = (): JSX.Element => {
  const { adUnit, formId, loading, setLoading, setTouched, setValid } =
    useContext(AddEditAdUnitContext)
  const priceGeniusAlwaysOnProps = usePriceGeniusAlwaysOn()
  const { update } = useSwitchAdUnitProduct({ setLoading })

  if (!adUnit) {
    return <Loading />
  }

  const handleSubmit = ({ id, priceGeniusEnabled }: CommonSchema): void =>
    void update({ id, product: 'priceGenius', value: priceGeniusEnabled })

  return (
    <Form<CommonSchema>
      id={formId}
      loading={loading}
      onSubmit={handleSubmit}
      onTouched={setTouched}
      onValid={setValid}
      schema={commonSchema}
      schemaDefaults={schemaDefault(adUnit, priceGeniusAlwaysOnProps)}
    >
      {() => <CommonForm />}
    </Form>
  )
}
