import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { BoxForm, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { RelatedList, RelatedListItem, RelatedSettings } from 'components/RelatedSettings'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { ROUTES } from 'routes'
import { Schema } from '../../schema'
import { CommonProps } from '../../types'
import { KNOWLEDGE_BASE_URL } from './constants'

export const NonConsentTrafficMonetizationSection = ({
  workspacePrebidConfiguration,
}: CommonProps): JSX.Element => {
  const { t: tC } = useTranslation('common')
  const { t: tG } = useTranslation('features/globalSetup', {
    keyPrefix: 'relatedSettings',
  })
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.nonConsentTrafficMonetization',
  })

  const { workspaceId } = useWorkspaceParam()

  const { control, watch } = useFormContext<Schema>()
  const { customNonConsentTrafficMonetizationEnabled } = watch()

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tC('learnMore'),
              url: KNOWLEDGE_BASE_URL,
            }}
          />
        }
        title={t('title')}
      />

      <Stack
        alignItems='flex-start'
        direction='row'
        spacing={2}
      >
        <BoxForm>
          <Switch
            control={control}
            label={t('customNonConsentTrafficMonetizationEnabled.label')}
            labelPlacement='start'
            name='customNonConsentTrafficMonetizationEnabled'
            tooltip={{
              content: t('customNonConsentTrafficMonetizationEnabled.tooltip'),
            }}
          />

          {customNonConsentTrafficMonetizationEnabled && (
            <BoxForm sx={{ ml: CONTAINER_SPACING }}>
              <Switch
                control={control}
                label={t('monetizeNonConsentTraffic.label')}
                labelPlacement='start'
                name='monetizeNonConsentTraffic'
              />
            </BoxForm>
          )}
        </BoxForm>

        {customNonConsentTrafficMonetizationEnabled && (
          <RelatedSettings title={tG('nonConsentTrafficMonetization.title')}>
            <RelatedList>
              <RelatedListItem
                content={tG('nonConsentTrafficMonetization.monetizeNonConsentTraffic')}
                url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, { workspaceId })}
                value={tG(
                  `settingEnabled.${workspacePrebidConfiguration.monetizeNonConsentTraffic}`,
                )}
              />
            </RelatedList>
          </RelatedSettings>
        )}
      </Stack>
    </Paper>
  )
}
