import { Form as FormContainer } from 'components/Form'
import { Form } from './Form'
import { useConfirmUser } from './hooks/useConfirmUser'
import { Schema, schema, schemaDefaults } from './schema'
import { UserCompleteSetupFormProps } from './types'

export const UserCompleteSetupForm = ({
  invitationToken,
}: UserCompleteSetupFormProps): JSX.Element => {
  const { confirm, errors: apiErrors, loading } = useConfirmUser()

  return (
    <FormContainer<Schema>
      externalErrors={apiErrors}
      loading={loading}
      onSubmit={confirm}
      schema={schema}
      schemaDefaults={schemaDefaults(invitationToken)}
    >
      {() => <Form />}
    </FormContainer>
  )
}
