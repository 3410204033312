import { useContext } from 'react'

import { Form } from 'components/Form'
import { GeneralPageContext } from 'features/globalSetup/contexts/GeneralPageContext'
import { useAbility } from 'providers/casl'
import { useUpdateWorkspace } from './hooks/useUpdateWorkspace'
import { Schema, schema, schemaDefault } from './schema'
import { WorkspaceForm } from './WorkspaceForm'

export const WorkspaceFormContainer = (): JSX.Element => {
  const ability = useAbility()
  const { formId, loading, setTouched, setValid, workspace } = useContext(GeneralPageContext)
  const { errors: apiErrors, update } = useUpdateWorkspace()

  return (
    <Form<Schema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={update}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(workspace, ability)}
    >
      {() => <WorkspaceForm />}
    </Form>
  )
}
