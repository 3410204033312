import { Edit, MoreVert, Tune } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { CellContext } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { ModulesCommonTabs } from 'features/globalSetup'
import { PrebidModuleType } from 'features/globalSetup/types/prebidModule'
import { canAccess } from 'features/globalSetup/utils'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { useAbility } from 'providers/casl'
import { DomainPrebidModuleSlice } from '../../hooks/useGetDomainPrebidModules/types'
import { prebidModulePathGenerator } from '../../prebidModulePathGenerator'

export const ActionsCell = ({
  moduleType,
  row: {
    original: { workspacePrebidModule },
  },
}: { moduleType: PrebidModuleType } & CellContext<
  DomainPrebidModuleSlice,
  unknown
>): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const navigate = useNavigate()
  const { anchor, close, isOpen, open } = useMenu()

  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.modules.list.action',
  })

  const handleEdit = (tab: ModulesCommonTabs): void =>
    navigate(
      prebidModulePathGenerator({ id: workspacePrebidModule.id, moduleType, tab, workspaceId }),
    )

  const cannotAccessModulePage: boolean = !canAccess({ ability, moduleType })

  const ModuleParametersAndConnectionItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-module-parameteres-and-connection'
      disabled={cannotAccessModulePage}
      icon={<Tune />}
      onClick={() => handleEdit('inventory-connection')}
      text={t('moduleParametersAndConnection')}
    />
  )

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      disabled={cannotAccessModulePage}
      icon={<Edit />}
      onClick={() => handleEdit('general')}
      text={tC('form.action.edit')}
    />
  )

  return (
    <>
      <IconButton
        disabled={cannotAccessModulePage}
        onClick={open}
      >
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <ModuleParametersAndConnectionItem />
        <EditItem />
      </Menu>
    </>
  )
}
