import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { sortToDTO, workspacePrebidModulesFromDTO } from './api/mapper'
import { GET_WORKSPACE_PREBID_MODULES } from './api/query'
import { WorkspacePrebidModuleSliceDTO } from './api/types'
import {
  UseGetWorkspacePrebidModules,
  UseGetWorkspacePrebidModulesProps,
  WorkspacePrebidModuleSlice,
} from './types'

export const useGetWorkspacePrebidModules = ({
  first,
  last,
  moduleType,
  sort,
  workspaceId,
}: UseGetWorkspacePrebidModulesProps): UseGetWorkspacePrebidModules => {
  const [workspacePrebidModules, setWorkspacePrebidModules] = useState<
    WorkspacePrebidModuleSlice[]
  >([])
  const [count, setCount] = useState<number>(0)

  const { loading } = useQuery(GET_WORKSPACE_PREBID_MODULES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({
      workspacePrebidModules: { nodes: workspacePrebidModules, totalCount: count },
    }): void => {
      if (workspacePrebidModules) {
        setWorkspacePrebidModules(
          workspacePrebidModules.map(
            (node: WorkspacePrebidModuleSliceDTO): WorkspacePrebidModuleSlice =>
              workspacePrebidModulesFromDTO(node),
          ),
        )
        setCount(count)
      }
    },

    variables: {
      first,
      last,
      moduleType,
      sort: sortToDTO(sort),
      workspaceId,
    },
  })

  return {
    count,
    loading,
    workspacePrebidModules,
  }
}
