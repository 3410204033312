import { Edit, MoreVert } from '@mui/icons-material'
import { IconButton, Menu } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useMenu } from 'hooks/useMenu'
import { ROUTES } from 'routes'
import { ActionsCellProps } from './types'

export const ActionsCell = ({ id }: ActionsCellProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('common')
  const { anchor, close, isOpen, open } = useMenu()
  const navigate = useNavigate()

  const EditItem = (): JSX.Element => (
    <ListItemButton
      data-cy='actions-edit'
      icon={<Edit />}
      onClick={() =>
        navigate(
          generatePath(ROUTES.GLOBAL_SETUP.PREBID.MODULES.GENERAL.EDIT, {
            id,
            tab: 'general',
            workspaceId,
          }),
        )
      }
      text={t('form.action.edit')}
    />
  )

  return (
    <>
      <IconButton onClick={open}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchor}
        onClose={close}
        open={isOpen}
      >
        <EditItem key='edit' />
      </Menu>
    </>
  )
}
