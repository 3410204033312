import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import {
  InventoryConnectionContext,
  InventoryConnectionContextType,
} from 'features/globalSetup/forms/Prebid/Modules/contexts/InventoryConnectionContext'
import {
  DomainsTable,
  InventoryConnectionForm,
} from 'features/globalSetup/forms/Prebid/Modules/InventoryConnectionForm'

export const InventoryConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.general.edit.inventoryConnection',
  })
  const [filters, setFilters] = useState<FilterValue[]>([])

  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const inventoryConnectionContextData: InventoryConnectionContextType = {
    moduleType: 'general',
  }

  if (!workspacePrebidModule) {
    return <Loading />
  }

  return (
    <Paper>
      <TableHeader
        bottomSpacing
        description={t('header.subtitle', { name: workspacePrebidModule.prebidModule.name })}
        filter={
          <Filter
            id='general-domains-table-filter'
            onChange={setFilters}
            placeholder={t('list.filter')}
            values={filters}
          />
        }
        title={t('header.title')}
      />

      <InventoryConnectionContext.Provider value={inventoryConnectionContextData}>
        <InventoryConnectionForm>
          <DomainsTable filters={filters} />
        </InventoryConnectionForm>
      </InventoryConnectionContext.Provider>
    </Paper>
  )
}
