import _ from 'lodash'
import { z } from 'zod'

import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'
import { Workspace } from 'features/workspace'
import { consentManagementSchema } from './sections/ConsentManagementSection/components/ConsentManagementCommonFields/schema'

export const schema = z
  .object({
    auctionDelay: z.number(),
    monetizeNonConsentTraffic: z.boolean(),
    s2sTimeoutMultiplier: z.number().min(0).max(100),
    workspaceId: z.string(),
  })
  .merge(consentManagementSchema)

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  workspacePrebidConfiguration: WorkspacePrebidConfiguration,
  workspaceId: Workspace['id'],
): Schema => {
  const fields: Pick<WorkspacePrebidConfiguration, keyof Omit<WorkspacePrebidConfiguration, 'id'>> =
    _.pick(workspacePrebidConfiguration, [
      'auctionDelay',
      'gdprTcfTimeout',
      'gdprTcfEnabled',
      'gppControlUsNat',
      'gppControlUsStates',
      'gppTimeout',
      'gppEnabled',
      'monetizeNonConsentTraffic',
      'prebidTimeout',
      'usPrivacyCcpaTimeout',
      'usPrivacyCcpaEnabled',
      's2sTimeoutMultiplier',
    ])

  return {
    ...fields,
    workspaceId,
  }
}
