import { Form as FormContainer } from 'components/Form'
import { Form } from './Form'
import { useRequestResetPassword } from './hooks/useRequestResetPassword'
import { Schema, schema, schemaDefaults } from './schema'

export const UserRequestPasswordResetForm = (): JSX.Element => {
  const { errors: apiErrors, loading, requestResetPassword } = useRequestResetPassword()

  return (
    <FormContainer<Schema>
      externalErrors={apiErrors}
      loading={loading}
      onSubmit={requestResetPassword}
      schema={schema}
      schemaDefaults={schemaDefaults}
      withLeaveDialog={false}
    >
      {() => <Form />}
    </FormContainer>
  )
}
