import { useContext } from 'react'

import { Form as FormProvider } from 'components/Form'
import Loading from 'components/Loading'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useSwitchAdUnitProduct } from '../hooks/useSwitchAdUnitProduct'
import { Form } from './Form'
import { Schema, schema, schemaDefault } from './schema'

export const FormContainer = (): JSX.Element => {
  const { adUnit, formId, loading, setLoading, setTouched, setValid } =
    useContext(AddEditAdUnitContext)
  const { update } = useSwitchAdUnitProduct({ setLoading })

  if (!adUnit) {
    return <Loading />
  }

  const handleSubmit = ({ id, refresherEnabled }: Schema): void =>
    void update({ id, product: 'refresher', value: refresherEnabled })

  return (
    <FormProvider<Schema>
      id={formId}
      loading={loading}
      onSubmit={handleSubmit}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefault(adUnit)}
    >
      {() => <Form />}
    </FormProvider>
  )
}
