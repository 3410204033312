import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ContentCopy } from 'components/ContentCopy'
import { StackForm, TextField } from 'components/Form'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/performance')
  const { control, watch } = useFormContext<Schema>()
  const { emails } = watch()

  return (
    <StackForm sx={{ justifyContent: 'space-between' }}>
      <TextField
        control={control}
        label={t('form.performanceReportUrl.label')}
        name='performanceReportUrl'
        placeholder={t('form.performanceReportUrl.placeholder')}
      />

      <TextField
        control={control}
        disabled
        InputProps={{
          endAdornment: <ContentCopy text={emails.join(', ')} />,
        }}
        label={t('form.emails.label')}
        name='emails'
        width={'lg'}
      />
    </StackForm>
  )
}
