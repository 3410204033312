import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { Schema } from '../../schema'
import { CommonProps } from '../../types'
import { KNOWLEDGE_BASE_URL } from './constants'
import { RelatedSettings } from './RelatedSettings'

export const AdvancedPrebidSection = ({
  workspacePrebidConfiguration,
}: CommonProps): JSX.Element => {
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.advancedPrebidSettings',
  })
  const { t: tc } = useTranslation('common')

  const { control, watch } = useFormContext<Schema>()
  const { customAuctionDelayEnabled } = watch()

  const ability = useAbility()
  const cannotUpdate: boolean = ability.cannot('update', 'Domain')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('subtitle')}
        title={t('title')}
      />

      <Stack
        alignItems='flex-start'
        direction='row'
        spacing={2}
      >
        <BoxForm>
          <Switch
            control={control}
            disabled={cannotUpdate}
            label={t('customAuctionDelayEnabled.label')}
            labelPlacement='start'
            name='customAuctionDelayEnabled'
            tooltip={{
              content: t('customAuctionDelayEnabled.tooltip'),
              link: {
                title: tc('learnMore'),
                url: KNOWLEDGE_BASE_URL,
              },
            }}
          />

          {customAuctionDelayEnabled && (
            <NumberField
              control={control}
              disabled={cannotUpdate}
              label={t('auctionDelay.label')}
              name='auctionDelay'
            />
          )}
        </BoxForm>

        {customAuctionDelayEnabled && workspacePrebidConfiguration && (
          <RelatedSettings auctionDelay={workspacePrebidConfiguration.auctionDelay} />
        )}
      </Stack>
    </Paper>
  )
}
