import { Box, Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'components/Form'
import { AddForm } from './AddForm'
import { NextFormContext } from './contexts/NextFormContext'
import { useInvitePublisherToWorkspace } from './hooks/useInvitePublisherToWorkspace'
import { addSchemaDefault, commonSchema, CommonSchema } from './schemas'
import { AddFormContainerProps } from './types'

export const AddFormContainer = ({ user }: AddFormContainerProps): JSX.Element => {
  const {
    errors: apiErrors,
    invitePublisherToWorkspace,
    loading,
  } = useInvitePublisherToWorkspace({
    userId: user.id,
  })
  const { setShowNextInputs, showNextInputs } = useContext(NextFormContext)
  const { t } = useTranslation('features/user')

  return (
    <>
      {showNextInputs && (
        <Form<CommonSchema>
          externalErrors={apiErrors}
          id='add-new-publisher'
          loading={loading}
          onSubmit={invitePublisherToWorkspace}
          schema={commonSchema}
          schemaDefaults={addSchemaDefault(user)}
        >
          {() => <AddForm />}
        </Form>
      )}

      <Box>
        <Button
          data-cy='add-next'
          disabled={showNextInputs}
          onClick={() => setShowNextInputs(true)}
          type='button'
        >
          {t('form.publisher.addNext')}
        </Button>
      </Box>
    </>
  )
}
