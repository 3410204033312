import { useContext } from 'react'

import { Form } from 'components/Form'
import Loading from 'components/Loading'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { useUpdateWorkspacePrebidModule } from '../hooks/useUpdateWorkspacePrebidModule'
import { editSchema, EditSchema, editSchemaDefault } from '../schemas/editSchema'
import { GeneralForm } from './GeneralForm'

export const EditFormContainer = (): JSX.Element => {
  const { formId, loading, setTouched, setValid, workspacePrebidModule } =
    useContext(PrebidModulesContext)
  const { errors: apiErrors, update } = useUpdateWorkspacePrebidModule({ moduleType: 'general' })

  if (!workspacePrebidModule) {
    return <Loading />
  }

  return (
    <Form<EditSchema>
      externalErrors={apiErrors}
      id={formId}
      loading={loading}
      onSubmit={(data: EditSchema) => update(workspacePrebidModule.id, data)}
      onTouched={setTouched}
      onValid={setValid}
      schema={editSchema}
      schemaDefaults={editSchemaDefault(workspacePrebidModule)}
    >
      {() => <GeneralForm />}
    </Form>
  )
}
